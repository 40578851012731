<template>
	<div>
		<span>{{$t("Discount")}}</span>
		<div class="flex gap-2">
			<input type="number" step="0.5" placeholder="0.0" v-model="discountValue" @change="debounce(handleDiscount, 1000)"
				class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:outline-custom_domain-500 focus:ring-custom_domain-500 focus:border-custom_domain-500 block w-full p-2.5" />
			<div class="flex">
				<button
					class="p-2 hover:bg-custom_domain-700 aria-selected:hover:bg-custom_domain-700 bg-custom_domain-300 aria-selected:bg-custom_domain-600 rounded-l-xl"
					v-bind:aria-selected="this.discountMode == 'PERCENT'" @click="this.discountMode = 'PERCENT'; handleDiscount()"
					value="PERCENT">
					<i class="text-white gg-math-percent"></i>
				</button>
				<button
					class="p-2 hover:bg-custom_domain-700 aria-selected:hover:bg-custom_domain-700 bg-custom_domain-300 aria-selected:bg-custom_domain-600 rounded-r-xl"
					v-bind:aria-selected="this.discountMode == 'VALUE'" @click="this.discountMode = 'VALUE'; handleDiscount()"
					value="VALUE">
					<i class="text-white gg-euro"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import OrderService from '@ivy/order-service'

export default {
	name: 'DiscountPanel',
	emits: ['new-price'],
	props: {
		mode: { // 'PERCENT' | 'VALUE'
            required: false,
			default: 'PERCENT',
            type: String,
        },
		value: {
			required: false,
			default: 0
		}
	},
	data() {
		return {
			discountMode: 'PERCENT',
			discountValue: 0,
		}
	},
	mounted() {
		this.discountMode = this.mode;
		this.discountValue = this.value;
	},
	watch: {
        mode: function(newVal) {
			this.discountMode = newVal;
		},
        value: function(newVal) {
			this.discountValue = newVal;
		}
	},
	methods: {
		async handleDiscount() {
			var response = await OrderService.update({
				discount: {
					type: this.discountMode
					, amount: this.discountMode == 'VALUE' ? this.discountValue : this.discountValue / 100
				}
			})

			this.$emit('new-price', response.data.price)
		},
		debounce(fn, wait) {
			if (this.searchTimer) {
				clearTimeout(this.searchTimer); // clear any pre-existing timer
			}
			this.searchTimer = setTimeout(() => {
				fn(); // call the function if time expires
			}, wait);
		},
	}
}
</script>

<style scoped></style>