<template>
    <div class="max-w-xs w-full bg-white rounded-2xl drop-shadow-lg p-4 flex flex-col gap-3 place-items-center hover:bg-custom_domain-50">
        <div class="flex gap-5">
            <h2>{{ item.reference }}</h2>
            <span>{{ "x" + item.quantity }}</span>
        </div>
        <div class="w-full flex content-center items-center justify-between">
            <div class="flex items-center gap-1">
                <button class="rounded-full h-8 w-8 flex justify-center items-center" :disabled="pending"
                    v-if="item.quantity > 1" @click="updateQuantity(item.quantity - 1)">
                    <i class="text-custom_domain-700 gg-math-minus"></i>
                </button>
                <button class="rounded-full h-8 w-8 flex justify-center items-center" :disabled="pending"
                    @click="updateQuantity(item.quantity + 1)">
                    <i class="text-custom_domain-700 gg-math-plus"></i>
                </button>
            </div>

            <div>
                <button class="rounded-full h-8 w-8 flex justify-center items-center" :disabled="pending"
                    v-if="isConfiguratorEditEnabled" @click="edit">
                    <i class="text-custom_domain-700 gg-pen"></i>
                </button>
                <button class="rounded-full h-8 w-8 flex justify-center items-center" :disabled="pending"
                    @click="remove">
                    <i class="text-custom_domain-700 gg-trash"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import RouterService from '@ivy/router-service'

export default {
    name: 'OrderItem',
    props: {
        item: {
            required: true
        },
        pending: {
            type: Boolean
        }
    },
    methods: {
        edit() {
            window.dispatchEvent(new CustomEvent(
                'request__configurator--retrieve-form-reference',
                {
                    detail: {
                        reference: this.item.reference
                    }
                }
            ))
            this.remove()
        },
        remove() {
            this.$emit('removeItem', this.item.reference)
        },
        updateQuantity(quantity) {
            this.$emit('updateQuantity', this.item.reference, quantity)
        },
    },
    computed: {
        isConfiguratorEditEnabled() {
            return this.item.origin == 'configurator' && RouterService.isOn('CONFIGURATOR_PAGE')
        }
    }
}
</script>