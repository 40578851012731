<template>
  <h2 class="mb-2 text-2xl leading-none tracking-tight text-custom_organization-900">{{$t("Order")}}</h2>
  <div v-if="order" class="flex flex-col gap-5">
    <div class="flex flex-wrap gap-4">
      <OrderItem v-for="item in order.items" :pending="pending" v-bind:key="item.reference" :item="item" @removeItem="removeItem" @updateQuantity="updateQuantity"></OrderItem>
    </div>

    <DiscountPanel
      @new-price="newPrice"
      :value="this.order?.discount?.amount"
      :mode="this.order?.discount?.type"
    ></DiscountPanel>

    <h1 class="flex flex-wrap gap-1 items-center">
      <span class="text-lg">{{$t("Total")}} :</span>
      <span class="before:border-custom_organization-700 text-xl text-custom_organization-900"
        :class="{'text-lg strikethrough': order.price.totalWithoutDiscount != order.price.total}">
        {{ order.price.totalWithoutDiscount }} €
      </span>
      <span v-if="order.price.totalWithoutDiscount != order.price.total" class="text-xl text-custom_organization-900">
        {{order.price.total}} €
      </span>
    </h1>

    <button class="flex gap-4 items-center justify-center rounded-full bg-custom_domain-700 text-light py-2 px-3 hover:bg-custom_domain-800" :disabled="pending">
      <span class="text-white" @click="download">{{$t('Generate quote')}}</span>
      <i class="text-white gg-file-document"></i>
    </button>
  </div>
</template>

<script>

import OrderService from '@ivy/order-service'

import OrderItem from './components/OrderItem.vue'
import DiscountPanel from './components/DiscountPanel.vue'

export default {
  name: 'App',
  components: {
    OrderItem, DiscountPanel
  },
  data() {
    return {
      order: {
        price: {
          totalWithoutDiscount: 0,
          total: 0
        }
      },
      pending: false,
      discountMode: 'PERCENT',
      discountValue: 0,
    }
  },
  mounted() {
    window.addEventListener('request__order--update', event => {
      if (!event.detail?.order) {
        this.update(true)
      } else {
        this.order = event.detail.order
      }
    })
    this.update()
  },
  methods: {
    newPrice(newPrice) {
      this.order.price = newPrice;
    },
    update(withRecommendation = false) {
      this.pending = true
      if(withRecommendation) {
        OrderService.getWithRecommendations()
          .then(response => {
            this.order = response.data
            console.log(this.order?.discount)
            if(this.order?.discount?.type == 'PERCENT') {
              // *100 because we get the discount from the api from 0 to 1
              // *100 / 100 is to round to 2 decimals
              this.order.discount.amount = Math.round(this.order.discount.amount *= 100 * 100) / 100;
            }
            console.log(this.order?.discount)
          })
          .catch(() => {
            this.update(false)
          })
          .finally(() => {
            this.pending = false
          })
      }else{
        OrderService.get()
          .then(response => {
            this.order = response.data
            console.log(this.order?.discount)
            if(this.order?.discount?.type == 'PERCENT') {
              // *100 because we get the discount from the api from 0 to 1
              // *100 / 100 is to round to 2 decimals
              this.order.discount.amount = Math.round(this.order.discount.amount *= 100 * 100) / 100;
            }
            console.log(this.order?.discount)
          })
          .finally(() => {
            this.pending = false
          })
      }
    },
    removeItem(reference) {
      this.pending = true
      OrderService.deleteItem(reference)
        .then(response => {
          this.order = response.data
        })
        .finally(() => {
          this.pending = false
        })
    },
    updateQuantity(reference, quantity) {
      this.pending = true
      OrderService.updateItem(reference, {
        quantity: quantity
      })
        .then(response => {
          this.order = response.data
        })
        .finally(() => {
          this.pending = false
        })
    },
    download() {
      OrderService.downloadQuotation()
    }
  }
}
</script>

<style scoped>
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 3px solid;
  border-radius: 3px;

  -webkit-transform:rotate(-5deg);
  -moz-transform:rotate(-5deg);
  -ms-transform:rotate(-5deg);
  -o-transform:rotate(-5deg);
  transform:rotate(-5deg);
}
</style>
